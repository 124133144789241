@font-face {
  font-family: 'vazir';
  src: url('../fonts/v2.ttf') format('woff'),
    url('../fonts/v2.ttf') format('truetype');
}

/* @font-face {
  font-family: 'vazir';
  src: url('../fonts/v3.ttf') format('woff'),
  url('../fonts/v3.ttf') format('truetype');
  font-weight: bold;
} */

@font-face {
  font-family: 'anek';
  src: url('../fonts/anek1.ttf') format('woff'),
    url('../fonts/anek1.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  box-sizing: border-box;
  font: normal 15px 'anek';
  line-height: 22px;
  text-decoration: none;
  outline: none;
}

html, body, #root {
  height: 100%;
  background: #fff;
}

body::before {
  content: "";
  background: #fff url('../images/pattern.png') repeat center / 200px;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.035;
  z-index: -1;
}

.container {
  width: calc(100% - 20px);
  min-width: 320px;
  max-width: 420px;
  margin: 0 auto;
}

.containerAuto {
  height: 100%;
}

.MuiBackdrop-root {
  /* background: none !important; */
}

.MuiPaper-root {
  /* background: none !important; */
  /* border-radius: 12px 12px 0 0; */
}

.MuiDrawer-paperAnchorTop {
  /* border-radius: 0 0 12px 12px; */
}

.MuiCollapse-root {
  z-index: 100000;
  position: fixed;
  top: 12px;
}

.app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  /* padding: 40px 0 58px; */
}

.logo {
  background: url('../images/logo.svg') no-repeat center / contain;
  width: 230px;
  height: 160px;
}

.logoSmall {
  width: 72px;
  height: 52px;
}

.detail {
  /* max-width: 290px; */
  text-align: center;
  margin-top: 30px;
}

.buttons {
  margin-top: 30px;
  display: flex;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.blueButton {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #3B7BF3;
  height: 52px;
  color: #fff;
  border: none;
}

.blueButton {
  width: 100%;
}

.blueButton i {
  font-size: 18px;
  display: block;
  margin-top: 3px;
}

.android::before {
  content: '';
  background: url('../images/android.svg') no-repeat center / contain;
  width: 24px;
  height: 24px;
}

.fastChange {
  background: #999;
}

.headComponent {
  padding: 25px;
}

.headComponent h2 {
  font-weight: bold;
  font-size: 17px;
}

.headComponent p {
  font-size: 13px;
  color: #666;
}

.form {
  display: flex;
  gap: 15px;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
  width: 100%;
}

.inputParent {
  width: 100%;
}

.inputParent span {
  color: #222B40;
  font-size: 15px;
  margin: 0 5px 2px;
  display: block;
}

.form input, .selectBox {
  background: #fff;
  border-radius: 10px;
  width: 100%;
  border: 1px solid #EBEBEB;
  font-size: 13px;
  padding: 12px 14px 10px;
}

.selectBox {
  padding: 0;
}

.selectBox select {
  background: none;
  border: none;
  padding: 14px;
  width: calc(100% - 12px);
  margin-right: 12px;
}

.showToken {
  display: flex;
  flex-direction: row;
  /* flex: 1; */
  align-items: left;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f8f8f8;
  padding: 10px 15px;
}

.showToken div {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;
}

.showToken div:nth-of-type(1) {
  width: 80%;
}

.showToken div:nth-of-type(2) {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: none;
}

.showToken span {
  text-wrap: wrap;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.showToken div:nth-of-type(1) span:nth-of-type(1) {
  /* font-weight: bold; */
}

.showToken div:nth-of-type(1) span:nth-of-type(2),
.showToken div:nth-of-type(1) span:nth-of-type(3),
.showToken div:nth-of-type(1) span:nth-of-type(5),
.showToken div:nth-of-type(1) span:nth-of-type(4) {
  color: #666;
  font-size: 13px;
}

.copy {
  background: url('../images/copy.svg') no-repeat center / contain;
  width: 22px;
  height: 22px;
  display: block;
  cursor: pointer;
}

.active, .deactive {
  background-image: url('../images/tick.svg');
}

.edit {
  background-image: url('../images/pen.svg');
}

.deactive {
  opacity: 0.4;
  filter: grayscale(1);
}

.buttonsDown {
  grid-template-columns: 1fr;
  width: auto;
}

.buttonsDown span {
  gap: 4px;
}

.buttonsDown span::before {
  content: '';
  background: url('../images/contact.svg') no-repeat center / contain;
  width: 20px;
  height: 20px;
}

.showToken .ucodeParent {
  flex: 0;
}

.ucode {
  background: url('../images/ucode.png') no-repeat center / contain;
  width: 60px;
  height: 60px;
}

.buttonsMain span.swapSection {
  width: 100%;
}

.buttonsMain span {
  justify-content: left;
}

.buttonsDev {
  gap: 5px;
}

.buttonsDev span {
  background: #333;
  color: #fff;
  font-size: 12px;
  width: calc(100% / 3 - 4px);
  height: 30px;
  border: none;
}

.receiveIcon {
  background: url('../images/deposit.svg') no-repeat center / contain;
  width: 19px;
  height: 19px;
  /* margin: 0 5px 0 14px; */
}

.receiveIconLarge {
  width: 36px;
  height: 36px;
}

.sendIcon {
  background-image: url('../images/send.svg');
}

.bonusIcon {
  background-image: url('../images/star1.svg');
}

.useIcon {
  background-image: url('../images/activeVoucher.svg');
}

.createIcon {
  background-image: url('../images/createVoucher.svg');
}

.transIcon {
  background-image: url('../images/history.svg');
}

.vouchersIcon {
  background-image: url('../images/vouchers.svg');
}

.swapIcon {
  background-image: url('../images/swap.svg');
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #f5f5f5;
  border-bottom: 1px solid #ddd;
  /* height: 42px; */
  padding: 6px 0;
}

.header h3 {
  margin-top: 4px;
}

.menu {
  background: url('../images/menu.svg') no-repeat center / contain;
  width: 22px;
  height: 21px;
  display: block;
  cursor: pointer;
  transform: rotate(180deg);
  margin-left: 10px;
}

.setting {
  background-image: url('../images/settings.svg');
  width: 24px;
  height: 24px;
}

.header .container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listBalance {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 16px;
  width: 100%;
}

.listBalance h4 {
  color: #333;
  text-align: center;
  margin: 0 auto;
  margin-top: -12px;
  margin-bottom: -10px;
  background: #ddd;
  border-radius: 100px;
  font-size: 12px;
  width: 90px;
  padding: 6px 0 4px;
  line-height: 13px;
}

.listBalance div {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  gap: 7px;
  padding: 8px;
}

.listBalance div:nth-last-of-type(1) {
  border: none;
}

.listBalance span, .listBalance i {
  font-size: 13px;
  margin-top: 3px;
}

.listBalance span {
  width: 150px;
}

.listBalance div img {
  width: 24px;
  height: 24px;
}

.list {
  /* flex: 1; */
  /* overflow-x: scroll; */
  /* height: 300px; */
  /* padding-bottom: 30px; */
}

.showTrans div span {
  line-height: 18px;
}

.showTrans {
  gap: 10px;
  align-items: center;
}

.showTrans img {
  width: 40px;
  height: 40px;
}

.showVoucher div:nth-of-type(1) {
  width: 70%;
}

.selecting {
  display: flex;
  gap: 8px;
  padding: 30px;
  flex-direction: column;
}

.selectInItem {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-align: center;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 8px;
  gap: 8px;
  cursor: pointer;
}

.selectInItem img {
  width: 32px;
  height: 32px;
  object-fit: contain;
  background-color: #eee;
  border-radius: 100px;
}

.selectInItem span {
  margin-top: 3px;
}

.publicCopy {
  background-color: #F7F7F7;
  border: 1px solid #EBEBEB;
  border-radius: 8px;
  padding: 10px 15px;
  margin: 10px 0 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.publicCopy img {
  width: 80px;
  margin: 0 auto;
}

.publicCopy span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.feeData {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  margin-bottom: 6px;
}

.blueButtonCenter {
  margin: 0 auto;
}

.limitData {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #eee;
  border-radius: 8px;
  padding: 12px 14px;
  margin: 15px 0 5px;
}

.limitDataLeft {
  align-items: flex-start !important;
}

.limitData span {
  font-size: 14px;
}

.headImage {
  display: flex;
  align-items: center;
  gap: 10px;
}

.headImage img {
  width: 60px;
  height: 60px;
}

.beforeTitle {
  text-align: center;
  font-size: 12px;
}

.menuLeft {
  padding: 30px;
}

.lineMenu {
  height: 2px;
  background-color: #3B7BF3;
}

.menuList {
  display: flex;
  gap: 5px;
  margin-top: 20px;
  flex-direction: column;
}

.menuList span {
  background-color: #dddddd99;
  border-radius: 8px;
  padding: 10px 12px;
  color: #333;
  cursor: pointer;
}

.menuList i {
  font-size: 12px;
  margin: 20px 0 0 2px;
}

.menuList i::before {
  content: '#';
}

.version {
  padding: 25px 0 0 5px;
  display: block;
  font-size: 12px;
  color: #666;
}

.logoMenu {
  width: 50px;
  height: 33px;
}

.tabs {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin: 10px 0 0;
  width: 100%;
}

.tabs span {
  flex: 1;
  background-color: #778096;
  text-align: center;
  color: #fff;
  border-radius: 10px;
  padding: 9px 0;
  font-size: 15px;
  cursor: pointer;
  display: block;
}

.tabsCoin {
  overflow: scroll;
  flex-wrap: wrap;
  flex-direction: column;
  height: 32px;
}

.tabsCoin span {
  width: 80px;
}

.tabs span.inActiveTabs {
  background: #3B7BF3;
}

.activeVoucher {
  background-color: #08c949;
  border-radius: 16px;
  color: #fff;
  padding: 1px 6px;
  line-height: 14px;
  font-size: 12px;
  display: inline-block;
}

.usedVoucher {
  background: #ed361a;
}

.success {
  text-align: center;
  color: #08c949 !important;
  display: block;
  margin-top: 10px;
  font-size: 15px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.bottom {
  top: auto;
  bottom: 0;
  border: none;
  border-top: 1px solid #ddd;
}

.shortcuts a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1px;
  cursor: pointer;
}

.shortcuts a b {
  color: #778096;
  font-size: 14px;
}

.shortcuts a.active b {
  color: #222B40;
}

.shortcuts a::before {
  content: '';
  display: block;
  background: #778096;
  mask-image: url('../images/wallet.svg');
  -webkit-mask-image: url('../images/wallet.svg');
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: center;
  -webkit-mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  width: 25px;
  height: 25px;
}

.shortcuts a.active::before {
  background: #222B40;
}

.shortcuts a:nth-of-type(2)::before {
  mask-image: url('../images/market.svg');
  -webkit-mask-image: url('../images/market.svg');
}

.shortcuts a:nth-of-type(3)::before {
  mask-image: url('../images/money.svg');
  -webkit-mask-image: url('../images/money.svg');
}

.shortcuts a:nth-of-type(4)::before {
  mask-image: url('../images/soccer.svg');
  -webkit-mask-image: url('../images/soccer.svg');
  transform: scale(1.45);
}

.shortcuts a:nth-of-type(5)::before {
  mask-image: url('../images/score.svg');
  -webkit-mask-image: url('../images/score.svg');
}

.backToList {
  padding-top: 12px;
  cursor: pointer;
  text-align: center;
}

.parentSetting {
  padding-bottom: 0;
  width: 100%;
  flex: 1;
  padding-top: 25px;
}

.settingLiner {
  border-bottom: 1px solid #EBEBEB;
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.settingLiner i {
  font-size: 14px;
  color: #222;
  /* margin-top: 2px; */
}

.checkbox {
  background-color: #ddd;
  width: 20px;
  height: 20px;
  border-radius: 6px;
}

.checkboxActive {
  background-color: #3B7BF3;
}

.settingTitle {
  font-size: 17px;
  /* font-weight: bold; */
  color: #222B40;
}

.joinTo {
  font-size: 13px;
}

.joinTo b {
  cursor: pointer;
  color: #3B7BF3;
}

.marketList {
  flex: 1;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 90px;
}

.marketList>div {
  border-top: 1px solid #EBEBEB;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 8px 10px;
}

.marketList>div img {
  width: 32px;
  height: 32px;
}

.marketView {
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 3px;
}

.marketView span {
  font-size: 14px;
  line-height: 15px;
}

.marketView span:nth-of-type(2) {
  font-size: 12px;
  color: #888;
}

.marketView:nth-of-type(2) span:nth-of-type(2) {
  font-size: 11px;
}

.marketPercent {
  margin-top: 4px;
  background-color: #08c949;
  border-radius: 6px;
  color: #fff;
  padding: 3px 0 1px;
  font-size: 13px;
  width: 54px;
  text-align: center;
}

.marketPriceRed {
  background-color: #ed361a;
}

.search {
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 11px;
  gap: 7px;
  width: 100%;
}

.search input {
  border: none;
  background: none;
  flex: 1;
  color: #333;
  padding: 7px 0 5px;
  font-size: 13px;
}

.search i {
  display: block;
  background: url('../images/search.svg') no-repeat center / contain;
  width: 14px;
  height: 14px;
}

.tabsExpress {
  padding-top: 10px;
}

.marketViewStable {
  align-items: flex-end;
}

.fastStable {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #eee;
  padding: 7px 0 15px;
  cursor: pointer;
}

.fastStable:nth-last-of-type(1) {
  border-bottom: none;
  padding-bottom: 0;
}

.fastStable i {
  display: block;
  width: 32px;
  height: 32px;
}

.selectingFast {
  padding: 20px 0 0;
}

.fastStable h5 {
  font-size: 17px;
  color: #222B40;
  line-height: 16px;
  font-weight: 700;
}

.fastStable h6 {
  font-size: 12px;
  line-height: 16px;
  color: #555;
  font-weight: normal;
}

.walletSymbol {
  text-align: center;
  display: block;
  padding-top: 5px;
}

.listBalanceSingle {
  border: none;
}

.listBalanceSingle h4 {
  width: 160px;
  border: none;
  margin: 0 auto;
}

.direct {
  font-size: 16px;
  color: #222B40;
  padding: 0 30px;
  line-height: 22px;
  text-align: center;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formBottom {
  margin-bottom: 10px;
}

.xbet {
  background: url('../images/oneXBet.svg') no-repeat center / contain;
  width: 100px;
  height: 70px;
  margin: 0 auto;
}

.onexicon {
  background-image: url('../images/oneXBet.svg');
  transform: scale(2);
}

.splashContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-x: scroll;
}

.splashInfo {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
}

.splashInfoCenter {
  align-items: 'stretch';
}

.infoText {
  font-size: 20px;
  color: #222B40;
  text-align: center;
  line-height: 28px;
  max-width: 340px;
}

.bottomSplash {
  background-color: #fff;
  border: 1px solid #EBEBEB;
  border-bottom: none;
  border-radius: 50px 50px 0 0;
  align-items: center;
}

.innerData {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  padding: 3px 0;
}

.innerData span {
  color: #778096;
  font-size: 13px;
}

.dataRegister {
  border-radius: 50px 50px 0 0;
  background-color: #222B40;
  margin: 0 0 0 -1px;
  width: calc(100% + 2px);
}

.innerText {
  color: #778096;
  font-size: 13px;
}

.bottomButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 50px;
  padding-bottom: 0;
}

.regButton {
  display: flex;
  background-color: #fff;
  height: 80px;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-radius: 20px 0 0 20px;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
}

.regButton i, .topButton i {
  width: 22px;
  height: 22px;
  display: block;
  background: #222B40;
  mask-image: url('../images/log.svg');
  -webkit-mask-image: url('../images/log.svg');
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: center;
  -webkit-mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
}

.topButton i {
  width: 34px;
  height: 34px;
}

.singButton {
  background-color: #3B7BF3;
  border-radius: 0 20px 20px 0;
}

.singButton i {
  background: #fff;
  mask-image: url('../images/pen.svg');
  -webkit-mask-image: url('../images/pen.svg');
}

.wonFast {
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 16px;
  padding: 25px 0 20px;
  display: block;
}

.regButton span {
  color: #222B40;
  font-size: 20px;
}

.singButton span {
  color: #fff;
}

.fastDl {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}

.fastDl span, .fastDl a {
  background: #778096;
  border-radius: 12px;
  flex: 1;
  text-align: center;
  color: #fff;
  padding: 12px 0;
  cursor: pointer;
}

.topSignup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px 30px;
  align-items: center;
}

.topButton {
  display: flex;
  flex-direction: row;
  gap: 7px;
  align-items: center;
  /* margin-top: -10px; */
}

.topButton h3 {
  color: #222B40;
  font-size: 29px;
  /* margin-top: 15px; */
}

.goLogin {
  color: #778096;
  font-size: 17px;
  margin: 20px 0;
  text-align: center;
  cursor: pointer;
}

.MuiAlert-message {
  padding: 9px 0 3px !important;
}

.topMenu {
  background-color: #3B7BF3;
  flex-direction: row;
  align-items: center;
  padding: 14px;
  width: 100%;
  display: flex;
  border-radius: 15px;
}

.topMenuLeft {
  flex: 1;
}

.topTitle {
  color: #fff;
  font-size: 20px;
}

.topTitleDetail {
  margin-top: -6px;
  color: #778096;
  font-size: 14px;
}

.walletBoxIcon {
  background: url('../images/walletInfo.svg') no-repeat center / contain;
  position: absolute;
  top: -50px;
  right: 10px;
  width: 180px;
  height: 180px;
}

.eyeIcon {
  background: #222B40;
  mask-image: url('../images/eye.svg');
  -webkit-mask-image: url('../images/eye.svg');
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: center;
  -webkit-mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  width: 26px;
  height: 26px;
  margin-left: 10px;
  cursor: pointer;
}

.eyeIconActive {
  background: #fff;
}

.arrowIcon {
  background: url('../images/arrow.svg') no-repeat center / contain;
  width: 7px;
  height: 12px;
}

.walletBox {
  background-color: #222B40;
  border-radius: 0 0 17px 17px;
  position: relative;
  overflow: hidden;
}

.walletBoxInner {
  background-color: #3B7BF3;
  border-radius: 0 0 17px 17px;
  padding: 20px 25px 15px;
  display: flex;
  flex-direction: row;
}

.boxBalance {
  flex: 1;
}

.toolsBox {
  display: flex;
  flex-direction: row;
  gap: 7px;
  z-index: 1;
}

.toolsBox>div {
  cursor: pointer;
}

.walletBoxIcon {
  position: absolute;
  top: -50px;
  right: 10px;
}

.sumBalance {
  color: #fff;
  font-size: 14px;
}

.balance {
  color: #fff;
  font-size: 30px;
  margin: 4px 0 20px;
}

.balanceDollar {
  color: #fff;
  font-size: 20px;
}

.toAnother {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin-top: -4px;
}

.toAnotherSmall {
  color: #fff;
  font-size: 12px;
  font-weight: normal;
}

.parentTrsBox {
  padding: 10px 20px;
  flex-direction: row;
  gap: 25px;
  display: flex;
  position: relative;
}

.trsBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  cursor: pointer;
}

.trsText {
  color: #fff;
  font-size: 16px;
}

.trsTextSmall {
  color: #EBEBEB;
  font-size: 10px;
  margin-top: -8px;
}

.topSort {
  flex-direction: row;
  margin-top: 14px;
  align-items: center;
  gap: 15px;
  display: flex;
}

.sortIcon {
  display: block;
  background: #778096;
  mask-image: url('../images/sort.svg');
  -webkit-mask-image: url('../images/sort.svg');
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: center;
  -webkit-mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  /* background: url('../images/sort.svg') no-repeat center / contain; */
  width: 11px;
  height: 13px;
}

.sortIconLow {
  transform: rotate(180deg);
}

.sortIconActive {
  background: #3B7BF3;
}

.topSortTitle {
  color: #222B40;
  font-size: 22px;
  flex: 1;
  /* margin-top: 8px; */
  /* margin-bottom: -11px, */
}

.sort {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  cursor: pointer;
}

.sortText {
  color: #778096;
  font-size: 13px;
  /* margin-top: 8px; */
}

.sortTextActive {
  color: #3B7BF3;
}

.fastMenu {
  width: calc(100% - 50px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 25px 10px;
}

.actionItem {
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  color: #222B40;
}

.actionItem div {
  font-size: 14px;
}

.menuLine {
  background-color: #F5F5F5;
  height: 5px;
  width: 100%;
}

.actionText {
  color: #222B40;
  line-height: 28px;
  margin-top: -2px;
  font-size: 16px;
}

.actionItem i {
  display: block;
  background: url('../images/send.svg') no-repeat center / contain;
  width: 30px;
  height: 30px;
}

.actionItem .receiveIcon {
  background-image: url('../images/deposit.svg');
}

.actionItem .swapIcon {
  background-image: url('../images/swap.svg');
}

.actionItem .voucherIcon {
  background-image: url('../images/voucher.svg');
}

.actionItem .historyIcon {
  background-image: url('../images/history.svg');
}

.starIcon {
  display: block;
  background: url('../images/star.svg') no-repeat center / contain;
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.assetSelect {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 14px;
}

.assetBalance {
  align-items: center;
  border-bottom: 1px solid #EBEBEB;
  padding: 15px 0;
}

.assetText {
  display: flex;
  color: #222B40;
  font-size: 14px;
  font-weight: bold;
}

.popupImage {
  width: 40px;
  height: 40px;
  object-fit: contain;
  background-color: #eee;
  border-radius: 100px;
}

.balanceImage {
  width: 36px;
  height: 36px;
}

.mainBalance {
  flex: 1;
  padding-bottom: 90px;
  overflow-x: scroll;
}

.walletLeft {
  flex: 1;
}

.walletRight {
  text-align: right;
}

.walletText {
  font-weight: bold;
  color: #222B40;
  flex: 1;
  font-size: 18px;
  margin-bottom: -4px;
  /* margin-top: 1px; */
}

.walletTextName {
  color: #778096;
  font-size: 13px;
  font-weight: normal;
}

.walletBalanceText {
  color: #222B40;
  font-size: 14px;
  margin-right: 3px;
  text-align: left;
  width: 120px;
}

.shortcutsParent {
  background-color: #fff;
  height: 56px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.shortcuts {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  height: 72px;
  border-radius: 100px;
  margin-top: -36px;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.shortcutsItem {
  text-align: center;
  display: flex;
  align-items: center;
  margin-top: 13px;
}

.itemText {
  color: #778096;
  font-size: 15px;
}

.itemTextActive {
  color: #222B40;
}

.pageTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
  padding-top: 0;
  align-items: center;
  width: 100%;
}

.pageTopTitle {
  color: #222B40;
  font-size: 32px;
  /* font-weight: bold; */
  /* margin-bottom: -8px; */
}

.pageTopTitle i {
  display: inline-block;
  margin-left: 4px;
  font-size: 15px;
}

.settingIcon {
  height: 38px;
  width: 38px;
  display: block;
  background: url('../images/settings.svg') no-repeat center / contain;
}

.backIcon {
  width: 16px;
  height: 22px;
  background: url('../images/back.svg') no-repeat center / contain;
}

.backParent {
  text-align: left;
  width: 100%;
  padding: 30px;
}

.fullPop {
  height: 100%;
  background-color: #fff;
}

.fullPopOpa {
  background-color: rgba(255, 255, 255, 0.97);
}

.fullPopContent {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}

.selecter {
  color: #222B40;
  font-size: 32px;
  text-align: center;
}

.fullPop .container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.iWill {
  font-size: 20px;
  color: #778096;
  text-align: center;
  margin-top: 12px;
}

.closeIcon {
  width: 19px;
  height: 19px;
  background: url('../images/close.svg') no-repeat center / contain;
}

.pageIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: -30px 30px 0;
  gap: 5px;
  padding-bottom: 20px;
}

.pageIconText {
  color: #778096;
  font-size: 22px;
  margin-top: 3px;
}

.iconImage {
  width: 28px;
  height: 28px;
}

.spacerFlex {
  flex: 1;
  overflow-x: scroll;
}

.blueButtonBottom {
  margin-bottom: 20px;
}

.parentSelector {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 30px;
}

.inTabs {
  flex: 1;
  background-color: #778096;
  text-align: center;
  color: #fff;
  border-radius: 8px;
  padding: 9px 0;
  display: block;
  cursor: pointer;
}

.menuLogo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 2px;
}

.menuLogo .backParent {
  padding: 8px 0;
}

.fastMenuFast {
  flex: 1;
  justify-content: space-around;
}

.listUp {
  flex: 1;
  overflow-x: scroll;
  margin-bottom: 10px;
}

.direct b {
  font-weight: bold;
}

.directNone {
  flex: none;
  margin-top: 40px;
}

.settingLinerFull {
  width: 100%;
  border: none;
  padding-left: 3px;
}

.listUpEnergy {
  margin-bottom: 92px;
}

.langTop {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  margin-top: 30px;
}

.langTop div {
  /* background: url('../images/fa.png') no-repeat center / contain; */
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: '#333';
  text-align: center;
}

.langTop div:nth-of-type(2) {
  /* background-image: url('../images/en.png'); */
}

.langTopSetting {
  margin: 0;
  justify-content: flex-start;
}

.selectAddress {
  display: flex !important;
  justify-content: space-between;
}

.selectAddress i {
  color: #3B7BF3;
}

.createForm {
  color: #111;
  font-weight: bold;
  background: #ddd;
  width: 100%;
  text-align: center;
  padding: 4px 0 2px;
  border-radius: 100px;
}

.createForm+div {
  width: 100%;
  display: flex;
  gap: 10px
}

.againWallet {
  background: #08c949;
  padding: 0;
  height: 40px;
}

.limitDataColor {
  background: #08c949;
  color: #fff;
}

.swapList {
  display: flex;
  margin-top: 30px;
}

.swapList>section {
  flex: 1;
}

.swapList>section:nth-of-type(1) {
  border-right: 1px solid #ccc;
}

.swapList h1 {
  text-align: center;
  font-weight: bold;
  color: #3B7BF3;
  font-size: 17px;
  margin-bottom: 20px;
}

.swapList section:nth-of-type(2) h1 {
  color: #ed361a;
}

.swapList .assetBalance {
  padding: 10px 15px;
}

.swapFrom .pageIcon {
  margin: 0;
}

.airdropGet {
  background: #f3dd1b;
  box-shadow: 0 3px 0 #d5c109;
  border-radius: 15px;
  margin-top: 10px;
  color: #1C274B;
  display: flex;
  align-items: center;
  /* gap: 5px; */
  justify-content: right;
  height: 44px;
  position: relative;
  flex: 1;
  /* padding-right: 10px; */
}

.airdropGet:nth-of-type(2) {
  background: #c4f31b;
  box-shadow: 0 3px 0 #b3db27;
}

.airdropGet span {
  display: block;
  background: url('../images/star1.svg') no-repeat center / contain;
  width: 42px;
  height: 42px;
  cursor: pointer;
  /* position: absolute; */
  /* left: 0px; */
  /* top: -7px; */
}

.airdropGet h2 {
  font-size: 16px;
  flex: 1;
  text-align: center;
}

.currentAir {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #08c949;
  background: #f7f7f7;
  border-radius: 100px;
  padding: 10px 30px;
  /* flex: 1; */
  /* text-align: center; */
}

.uwalletClicker {
  width: 200px;
  height: 200px;
  background: #3A7BF3;
  border-radius: 100px;
  border: 4px solid #1f57c0;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.airFlex {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.uwalletClicker::after {
  content: '';
  background: url('../images/diamond.svg') no-repeat center / contain;
  width: 140px;
  height: 140px;
  margin-top: 14px;
}

.infoAir {
  text-align: center;
  color: #555;
}

.uwalletClicker i {
  background: #11111193;
  color: #fff;
  position: absolute;
  padding: 7px 8px 5px;
  line-height: 12px;
  border-radius: 5px;
  animation: anim 1s linear forwards;
  opacity: 1;
}

@keyframes anim {
  to {
    opacity: 0;
    transform: translate(0, -100px);
  }
}

.oddsList {
  flex: 1;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 90px;
}

.searchBox {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchBox input {
  background: none;
  border: none;
  padding: 12px 14px 10px;
  flex: 1;
}

.searchBox span {
  background: url('../images/search.svg') no-repeat center / contain;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  cursor: pointer;
}

.inputParentMax {
  justify-content: space-between;
  display: flex;
}

.inputParentMax span:nth-of-type(2) {
  color: #08c949;
}

.inviteGet {
  background: #1bf3a6;
  box-shadow: 0 3px 0 #09d586;
  text-align: center !important;
  flex: none;
  justify-content: center;
}

.infoAir {
  text-align: center;
  color: #000;
}

.infoAir div {
  background: #eeeeee;
  border: 1px solid #d1d1d1;
  border-radius: 14px;
  color: #336cd6;
  width: 100%;
  font-size: 16px;
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 20px;
  padding: 16px 22px;
}

.topper {
  width: 100%;
  background-color: #222B40;
  border-radius: 17px;
  margin-top: 10px;
}

.airparent {
  display: flex;
  gap: 10px;
}

.forTab {
  margin: 10px 5px -9px;
  color: #666;
  display: block;
}

.usernamePB {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 15px;
  padding: 10px 0;
}

.airparentIn .airdropGet {
  margin: 0;
  padding: 5px 0;
  height: auto;
}

.airparentIn {
  margin-bottom: 30px;
}

.taskData {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.taskData div {
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  cursor: pointer;
  display: flex;
  gap: 5px;
}

.taskData div:nth-of-type(n+2)::before {
  content: '';
  background: url('../images/tick.svg') no-repeat center / contain;
  width: 22px;
  height: 22px;
  opacity: 0.3;
  filter: grayscale(1);
}

.taskData div.active:nth-of-type(n+2)::before {
  opacity: 1;
  filter: grayscale(0);
}

.taskData div h6 {
  flex: 1;
}

.taskData div:nth-last-of-type(1) {
  border-bottom: none
}

.taskData div:nth-of-type(1) {
  background: #ccc;
  color: #fff;
}

.fast1Data {
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-top: 20px;
}

.fast1Data div {
  cursor: pointer;
  padding: 12px 17px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.yourFriends i {
  display: block;
  margin-bottom: 3px;
}

.sent {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-break: break-word;
}

.sent h5 {
  color: #08c949;
  font-size: 22px;
}
