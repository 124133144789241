html[lang='fa'] * {
  font-family: 'vazir';

}

html[lang="fa"] .topSignup,
html[lang="fa"] .topButton,
html[lang="fa"] .trsBox,
html[lang="fa"] .topSort,
html[lang="fa"] .shortcuts,
html[lang="fa"] .assetSelect,
html[lang="fa"] .marketList>div,
html[lang="fa"] .pageTop,
html[lang="fa"] .pageIcon,
html[lang="fa"] .feeData,
html[lang="fa"] .tabs,
html[lang="fa"] .fastMenuFast,
html[lang="fa"] .settingLiner,
html[lang="fa"] .swapList,
html[lang="fa"] .showToken {
  flex-direction: row-reverse;
}

html[lang="fa"] .inputParent span,
html[lang="fa"] .trsBox,
html[lang="fa"] .topSortTitle,
html[lang="fa"] .walletLeft,
html[lang="fa"] .marketView,
html[lang="fa"] .menuList span,
html[lang="fa"] .menuList i,
html[lang="fa"] .version,
html[lang="fa"] .backParent,
html[lang="fa"] .settingTitle {
  text-align: right;
}

html[lang="fa"] .walletRight {
  text-align: left;
}

html[lang="fa"] .limitData {
  align-items: flex-end;
}

html[lang="fa"] .langTopSetting {
  justify-content: flex-start;
}

html[lang="fa"] .fastDl span,
html[lang="fa"] .blueButton,
html[lang="fa"] .goLogin,
html[lang="fa"] .wonFast,
html[lang="fa"] .actionItem div,
html[lang="fa"] .feeData span,
html[lang="fa"] .pageTopTitle,
html[lang="fa"] .directNone i,
html[lang="fa"] .joinTo,
html[lang="fa"] .menuList i,
html[lang="fa"] .taskData div,
html[lang="fa"] .infoAir,
html[lang="fa"] .fast1Data div,
html[lang="fa"] .backParent,
html[lang="fa"] .showToken div {
  direction: rtl;
}

html[lang="fa"] .trsBox .arrowIcon,
html[lang="fa"] .backIcon {
  transform: rotate(180deg);
}
